<template>
    <div id="video-player">
        <div class="video-player-nav">
            <button class="button-round home-button" @click="$router.push({ name: 'videos', query: { pCollection: collection, pSubCollection: subCollection}})">Back</button>

            <p class="video-title">{{ video ? video.title : "" }}</p>
        </div>

        <div class="player-container" v-if="video">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe :src="`https://player.vimeo.com/video/${video.videoId}?h=20740a2d12&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    props: [
        'videoId',
        'subCollection',
        'collection'
    ],
    computed: {
        ...mapGetters('teachers', ['teacherVideos']),
        ...mapState('resources', ['resources']),
        ...mapState('user', { userInfo: 'info', isAdmin: 'isAdmin' }),
        hasAccessToVideo() {
            if (this.isAdmin) {
                return true
            }

            return this.teacherVideos.includes(this.videoId) || this.video.free
        },
        video() {
            return this.resources.find(video => video.id == this.videoId)
        }
    },
    mounted() {
        this.$store.dispatch("resources/setupResourceBinds").then(() => {
            return this.$store.dispatch("teachers/bindList")
        }).then(() => {
            if (!this.hasAccessToVideo) {
                this.home()
            } else {
                this.recordVideoWatched(this.videoId)
            }
        })        
    },
    methods: {
        ...mapActions('teachers', ['recordVideoWatched']),
        home() {
            this.$router.push('/')
        }
    }
}
</script>